@charset "UTF-8";

/*
  ------------------------------------
  ####################################
   共通設定
  ####################################
  ------------------------------------
*/
/*
  ------------------------------------
   MediaScreen指定
  ------------------------------------
*/
// PC サイトサイズmax
$sitesize: 990px;
$tublet: 989px;
$sphone: 639px;
$sp320: 359px;
// Retina
$Retina2: -webkit-min-device-pixel-ratio;



/*
  ------------------------------------
   ベースプロパティ
  ------------------------------------
*/
$bg-color: #fff; // 使用色
$bg-color2: #f7f7f7; // 使用色 
$color01: #b5940c;
$white: #fff;
$black: #111;
$font-color: #484747;
$line-height: 1.5;
$a-color: #444;
$a-trans: 0.3s;
$basefont: 16px;

@mixin border{
  border:2px dotted #dbcb88;
  border-radius: 6px;
  line-height: 1.8;
}
/*
  ------------------------------------
   FONT指定
  ------------------------------------
*/

@mixin web-font01 {
  //font-family: "futura",'Noto Sans JP', sans-serif;
}
@mixin web-futura{
  //font-family: "futura";
}
@mixin web-Noto{
  //font-family:'Noto Sans JP';
}
/*
  ------------------------------------
   z-index管理
  ------------------------------------
*/
$frontmost:99999;
/*
  ------------------------------------
   a リセット
  ------------------------------------
*/
a{
    transition:0.6s;
    transition-property:background-color,color,opacity;
    opacity: 1;
	&:link{	text-decoration: none; color: #000;}
	&:visited{	text-decoration: none; color: #000;}
	&:hover{ opacity: 0.7;}
}

/*
  ------------------------------------
   レスポンシブ
  ------------------------------------
*/
$breakTb: 1079px;
$breakSp: 767px;

@mixin tb {
  @media (max-width: ($breakTb)) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: ($breakSp)) {
    @content;
  }
}
$break_mpc: 948px;
@mixin max_pc {
  @media screen and (min-width: ($break_mpc)) {
    @content;
  }
}

