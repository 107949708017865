@charset "UTF-8";

@import "config";
@import "module";

.preload *{
  transition:0s !important;
}
.note{
    font-size: 1.6rem;
    @include sp{
        font-size: 3vw;
    }
}

#contentswrapper {
.text {
    font-size: 1.4rem;
    line-height: 1.8;
    text-align: center;
    margin-bottom: 30px;
    @include sp{font-size: 1.3rem;}
    
}
    
.visual {
padding: 100px 0 240px;
background: url("../img/kv_back.png") no-repeat center 101% #89d2f5;
background-size: 100% auto;
text-align: center;
p {
  line-height: 2.4;
  font-size: 1.5rem;
  font-weight: bold;
  color:#323232;
  span{
    display: block;
    font-size: 1.2rem;
    color:#4c4c4c;
  }
}
h1 {
  max-width: 520px;
  margin: 20px auto ;
  img {
    width: 100%;
    height: auto;
  }
}
@include sp {
  background: url("../img/kv_back_sp.png") no-repeat center 101% #89d2f5;
  background-size: 100% auto;
  padding: 20vw 0 35vw;
  p{
    line-height: 1.8;
    spam{
      line-height: 1.2;
    }
  }
  h1{
    max-width: 80%;
  }
}
}
.news{
.inner_box {
    max-width: 630px;
}
.news_list{
    .news_list_item {
        dl {
            dt {}
            dd{ 
                padding-left: 20px;
                @include sp { padding-left: 0;}
            }
        }
    }
 }
   @include sp {padding-bottom: 8vw;}
}
.future{
    @include sp{
        padding: 45px 0;
    }
    .inner_box{
        padding:90px 60px;
        @include sp{
            padding:0;
        }
    }
        .lead_area{
          margin-bottom: 60px;
          padding: 30px;
          background-color: $bg-color2;
          @include sp{
              margin-top: 16px;
              margin-bottom: 30px;
              padding: 15px;
            }
          .text {
              margin-bottom: 0;
          }
          .list_text{
              line-height: 1.6;
              padding-left: 1.5em;
              position: relative;
              &:not(:last-child){
                  margin-bottom: 16px;
              }
              &:after{
                  content: "●";
                  position: absolute;
                  left: 0;
                  top: 0;
              }
          }
      }
        .section_back .lead_area{
          background-color: #fff;
      }
}
.about {
padding: 70px 0;
    @include sp{
        padding: 45px 0;
    }
.inner_box{
    padding:0 60px;
    @include sp{
        padding:0;
    }
}

  .leadtxt{
      margin-bottom: 30px;
  }
.point{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
    li{
      width: 222px;
        width: 26.57%;
        min-width: 200px;
      @include sp {
          width: 30%;
          min-width: 30%;
      }
    }
}
.iconbox{
  text-align: center;
  dt{
    padding: 0.7em;
    background-color:#b5940c;
    color:#fff;
    position: relative;
    margin-bottom: 40px;
    &:after{
      content:"";
      display: inline-block;
      width: 0;
      height: 0;
      border:13px solid transparent ;
      border-top-color: #b5940c;
      border-bottom: none;
      position: absolute;
      left:50%;
      top:100%;
      transform: translateX(-50%);
    }
    .letter2{
      letter-spacing: 0.2em;
    }
    .letter3{
      letter-spacing: 0.1em;
    }
    @include sp{
      margin-bottom:2em ;
    }
  }
  dd{
      width: 118px;
      margin: 0 auto;
      @include sp{
          width: 100%;
      }
      img{
          width: 100%;
      }
  }
}
@include sp {padding-bottom: 8vw;}
}
     
.process{
    .inner_box{
        padding:0 60px;
        @include sp{
            padding:0;
        }
    }
        .sub_block_1,.sub_block_2{
                padding: 70px 0;
                @include sp{
                    padding: 45px 0;
                }
            }
        .lead_area{
              margin-bottom: 60px;
              padding: 30px;
              background-color: $bg-color2;
              @include sp{
                  margin-bottom: 25px;
                  padding: 15px;
                }
              .text {
                  margin-bottom: 0;
              }
              .list_text{
                  line-height: 1.6;
                  padding-left: 1.5em;
                  position: relative;
                  &:not(:last-child){
                      margin-bottom: 16px;
                  }
                  &:after{
                      content: "●";
                      position: absolute;
                      left: 0;
                      top: 0;
                  }
              }

          }
        .section_back .lead_area{
             background-color: #fff;
         }
        .caution{
            display: flex;
            justify-content: flex-end;
            line-height: 1.6;
            p{
                display: inline-block;
                padding-left: 1.5em;
                position: relative;
            }
            @include sp{
                justify-content: flex-start;
            }
            span{
                position: absolute;
                top: 0;
                left: 0;
            }

        }
    
    .img_process{
        position: relative;
        img{
            
        } 
        .map_link{
            display: inline-block;
            text-align: center;
            letter-spacing: 0.14em;
            font-size: 1.2rem;
            font-weight: bold;
            color: #fff;
            width: 12.2%;
            padding: 0.3% 0;
            background-color: #c20025;
            position: absolute;
            left: 25%;
            top: 31.8%;
            
            -webkit-animation:blink 1.0s ease-in-out infinite alternate;
            -moz-animation:blink 1.0s ease-in-out infinite alternate;
            animation:blink 1.0s ease-in-out infinite alternate;
            @-webkit-keyframes blink{
                0% {opacity:0.4;}
                100% {opacity:1;}
            }
            @-moz-keyframes blink{
                0% {opacity:0.4;}
                100% {opacity:1;}
            }
            @keyframes blink{
                0% {opacity:0.4;}
                100% {opacity:1;}
            }
            
            @include sp{
                font-size: 1rem;
                width: 21%;
                padding: 0.9% 0;
                left: 16.2%;
                top: 34%;
            }
            a{
                color: #fff;
            }
        }
        
        
    }
    .partner_list{
        margin: 10px auto 40px;
        padding: 26px 6%;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include sp{
            flex-wrap: wrap;
        }
        .partner_item{
            margin: 0 8px;
            &-1{
                text-align: center;
                padding-bottom: 10px;
                position: relative;
                span{
                    width: 110px;
                    &:after{
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: #b5940c;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }
            }
            &-2{width: 135px;}
            &-3{width: 130px;}
            &-4{width: 85px;}
            &-5{width: 100px;}
            img{
                width: 100%
            }
            @include sp{
                margin: 0;
                &-1{
                    width: 100%;
                    margin-bottom: 10px;
                    span{
                        &:after{
                        width: 110px;;

                    }
                    }
                }
                &-2{ width: 23%;}
                &-3{ width: 20%;}
                &-4{ width: 17%;}
                &-5{ width: 15%;}
            }
        }
    }
    .btn{
        margin-bottom: 50px;
        @include sp {
            margin-bottom: 30px;
        }
    }
    
    

}

.contact,.bnr{
      padding: 90px 0 60px;
      text-align: center;
      line-height: 1.3;
      @include sp {
           margin-top: 20px;
            padding: 8vw 2%;
            box-sizing: border-box;
        }
      .text{
        font-size: 1.3rem;
        line-height: 1.6;
        color: #fff;
        width: 324px;
        margin: 0 auto 7px;
        padding: 1em;
        background-color:$color01;
        border-radius: 6px;
        position: relative;
          &:after{
            content: "";
            display: block;
            margin: 0 auto;
            width: 0;
            height: 0;
            border-top: 18px solid $color01;;
            border-right: 17px solid transparent;
            border-left: 17px solid transparent;
            position: absolute;
            bottom: -17px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1
          }
          @include sp{
              display: inline-block;
              width: auto;
              margin: 0 auto 4px;
              padding: 0.7em 1.2em;
              &:after{
                border-top: 10px solid $color01;;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                bottom: -9px;
              }
          }
      }
    .link_text{
        width: 500px;
        margin: 0 auto;
        position: relative;
        @include sp{
            width: 70%;
            min-width: 220px; 
        }
        span,a{
            display: block;
            color:$color01;
            font-size: 2.5rem;
            font-weight: bold;
            padding: 0.6em 1em;
            border: 3px solid $color01;
            background-color: #fff;
            border-radius: 6px;
            cursor: pointer;
            &:after {
                content: "";
                display: inline-block;
                width: 7px;
                height: 12px;
                background: url("../img/icon_arrow.png") no-repeat center center;
                background-size: contain;
                position: absolute;
                right: 8%;
                top: 50%;
                transform: translate(0,-50%);
                transition: 1s;
            }
            &:hover:after{
                transform: translate(100%,-50%);
            }
            @include sp{
                    font-size: 1.3rem;
                    border: 2px solid $color01;
                    &:after{
                        display: none;
                    }
                }
        }
    }
  }
.bnr{
      position: fixed;
      bottom:0px;
      width: 100%;
      p{
        opacity: 1;
        transition: 0.5s;
        &.out{
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    
    
//.solution{
//    text-align: center;
//    padding-bottom: 90px;
//    @include sp {
//        padding-bottom: 8vw;
//        .text {
//            padding: 0;
//        }
//    }
//}
//.workflow{
//    padding-bottom: 90px;
//    @include sp {
//        padding-bottom: 8vw;
//    }
//    .note_info{
//        text-align: center;
//        .note_title{
//            font-size: 1.8rem;
//            margin-bottom: 1em;
//        }
//        .note_text{
//            line-height: 1.6;
//            font-size: 1.4rem;
//            margin-bottom: 1.5em;
//        }
//        .note_list{
//            width: 450px;
//            margin: 0 auto 26px;
//            display: flex;
//            -webkit-box-pack: justify;
//            -ms-flex-pack: justify;
//            justify-content: space-between;
//            .note_item{
//                dl{
//                    .tier4{
//                        width: 166px;
//                        @include sp{
//                            width: 100px;
//                        }
//                    }
//                    .aisan{
//                        width: 137px;
//                        @include sp{
//                            width: 88px;
//                        }
//                    }
//                    dt{
//                        margin: 0 auto;
//                    a{
//                        display: block;
//                        img{ width: 100%; }
//                    }
//                    }
//                    dd{
//                        font-size: 1.2rem;
//                        padding-top: 0.5em;
//                    }
//                }
//            }
//            @include sp{
//                width: 84.37%;
//                max-width: 350px;
//                min-width: 300px;
//            }
//        }
//    }
//}
//.risk-assessment{
//    ul{
//        display: flex;
//        flex-wrap: wrap;
//        justify-content: space-between;
//        li{
//            width: 46%;
//            text-align: center;
//            margin-bottom: 50px;
//            figcaption {
//                margin-top: 0.5em;
//                font-size: 2rem;
//                line-height: 1.4;
//            }
//            @include sp {
//                margin-bottom: 20px;
//                figcaption{
//                    font-size: 1.4rem;
//                }
//            }
//        }
//    }
//    @include sp {
//        padding-bottom: 8vw;
//    }
//}
//.soft{
//        padding-bottom: 90px;
//    .img{
//        margin-bottom: 20px;
//    }
//    .text{
//        width: 96%;
//        margin: 0 auto;
//        text-align: right;
//    }
//    @include sp {
//        padding-bottom: 8vw;
//    }
//}
//.map_data{
//    .inner_box{padding-top: 0;}
//    .cooperation_logo{
//        width: 100%;
//        max-width: 990px;
//        text-align: right;
//        margin: 0 auto;
//        padding: 24px 24px 0 0;
//        box-sizing: border-box;
//        @include sp {padding: 2vw 2% 0 0;}
//        a{
//            display: inline-block;
//            width: 120px;
//            @include sp{
//                width: 90px;
//            }
//            img{
//                width: 100%;
//            }
//        }
//    }
//    @include sp {
//        padding-bottom: 8vw;
//    }
//}
//.pioneer{
//    dl {
//        display: flex;
//        flex-wrap: wrap;
//        justify-content: space-around;
//        align-items: center;
//        padding: 3%;
//        background-color: #e7e7e7;
//        margin-bottom: 50px;
//        border-radius: 10px;
//        &:nth-of-type(1) {
//            dt {
//                order: 3;
//            }
//        }
//        dt, dd {
//            width: 31%;
//            text-align: center;
//        }
//        dt {
//            line-height: 1.6;
//            font-size: 1.7rem;
//            .color1 {
//                font-size: 2.4vw;
//                color: $color01;
//                display: block;
//                @include max_pc{font-size: 2.4rem;}
//            }
//            .font_l {
//                font-size: 3.6vw;
//                @include max_pc{font-size: 3.6rem;}
//            }
//        }
//        dd {
//            img {max-width: 100%;}
//        }
//        @include sp {
//            margin-bottom: 20px;
//            dt,dd{
//                width: 46%;
//            }
//            dt{
//                font-size: 1.4rem;
//                width: 100%;
//                order: 0 !important;
//                padding: 0 0 0.5em;
//                .color1{font-size: 1.6rem;}
//                .font_l {font-size: 2rem;}
//            }
//        }
//    }
//    .map {
//        position: relative;
//        max-width: 910px;
//        margin: 0 auto;
//        padding-bottom: 60px;
//        .txt {
//            position: absolute;
//            left: 3%;
//            top: 0;
//            line-height: 1.4;
//            width: 59%;
//            font-size: 1.8rem;
//            font-weight: bold;
//        }
//        .link_img{
//            width: 260px;
//            position: absolute;
//            right: 0;
//            bottom: 80px;
//            a{
//                display: inline-block;
//                img{
//                    width: 100%;
//                }
//            }
//        }
//        @include sp {
//            padding-bottom: 8vw;
//            .txt{
//                position: static;
//                width: 100%;
//                font-size: 1.2rem;
//                margin-bottom: 20px;
//            }
//            .link_img{
//                width: 31.25%;
//                min-width: 130px;
//                position: absolute;
//                right: 0;
//                bottom: 3.5vw;
//            }
//        }
//    }
//}

  @include sp {
	font-size: 1.2rem;
  }
}
